<template lang="pug">
v-container
    v-row
        v-col
            v-img#mugshot(v-bind:src="imagePath")
            DescriptionSection#descriptions(v-bind:items="descriptionItems")
</template>

<script lang="ts">
import Vue from 'vue';
import DescriptionSection from '../components/DescriptionSection.vue';
import Mugshot from '../assets/matthew_swartzendruber.jpg';
import aboutData from '../data/about';

export default Vue.extend({
    name: 'About',
    components: {
        DescriptionSection,
    },
    data() {
        return {
            imagePath: Mugshot,
            descriptionItems: aboutData,
        };
    },
});
</script>

<style lang="sass" scoped>
#mugshot
    border-radius: 50%
    height: 35%
    width: 200px
    text-align: center
    margin-left: auto
    margin-right: auto
    border: 6px solid #303030

#descriptions
    margin-top: 20px
</style>
