<template lang="pug">
v-container
    v-row.section(v-for="item in items")
        v-col
            p.sectionHeader {{ item.header }}
            .text(v-for="paragraph in parseText(item.text)")
                p(v-html="paragraph")
</template>

<script lang="ts">
export interface DescriptionItem {
    header: string;
    text: string;
}

export default {
    name: 'DescriptionSection',
    props: {
        items: Array as () => DescriptionItem[],
    },
    methods: {
        parseText(text: string): string[] {
            return text.split(/\n/g);
        },
    },
};
</script>

<style lang="sass" scoped>
@import '../styles/sections.sass'
</style>
