<template lang="pug">
#resume
    h1 Resume
    v-row
        v-col
            p.section.sectionHeader Summary of Skills
            Skills(v-bind:skills="skills" v-bind:layoutOptions="skillsLayout")
    v-row
        v-col
            p.section.sectionHeader Professional Experience
            ProfessionalExperience(v-bind:experiences="experiences")
    v-row
        v-col
            p.section.sectionHeader Education
            v-container(v-for="item in education")
                v-row
                    v-col
                        .text-left
                            p.font-weight-bold {{ item.establishmentName }}
                    v-col(md="auto")
                        p.text-center {{ item.location }}
                    v-col
                        p.text-right {{ item.attendedDate }}
                v-row
                    v-col
                        p.text-left.font-italic {{ item.accomplishment }}
    v-speed-dial(
        fixed
        bottom
        right
        transition="slide-y-reverse-transition"
    )
        template(v-slot:activator="")
            v-btn(elevation="2" dark fab)
                v-icon mdi-export
        v-tooltip(left v-bind:max-width="tooltipWidth")
            template(v-slot:activator="{ on, attrs }")
                v-btn(dark small fab v-bind="attrs" v-on="on" @click="redirectToResume(false)")
                    v-icon mdi-download
            span Download to PDF
        v-tooltip(left v-bind:max-width="tooltipWidth")
            template(v-slot:activator="{ on, attrs }")
                v-btn(dark small fab v-bind="attrs" v-on="on" @click.stop="setPasscodeDialog(true)")
                    v-icon mdi-download-lock
            span Download to PDF with Passcode
    v-dialog(
        v-model="showPasscodeDialog"
        retain-focus
        max-width="300px"
    )
        v-card
            v-card-title
                span Rich Download
            v-card-text
                p.text-left Downloading via this button adds additional contact information to the resume as long as you enter the correct passcode.
            v-text-field.input-field(
                v-model="passcodeValue"
                label="Passcode"
                type="password"
                autofocus
            )
            v-card-text
                p.text-left.error-message(v-if="passcodeErrorMessage != ''") {{ passcodeErrorMessage }}
            v-card-actions
                v-btn(text @click.stop="setPasscodeDialog(false)") Cancel
                v-btn(text @click.stop="redirectToResume(true)") Download
</template>
<script lang="ts">
import Vue from 'vue';
import Skills from '../components/Skills.vue';
import ProfessionalExperience from '../components/ProfessionalExperience.vue';
import skillData from '../data/skills';
import experienceData from '../data/experience';
import educationData from '../data/education';
import { createPDF, ContactInfo } from '../lib/pdf';

export default Vue.extend({
    name: 'Resume',
    components: {
        ProfessionalExperience,
        Skills,
    },
    data(): Record<string, unknown> {
        return {
            showPasscodeDialog: false,
            passcodeValue: '',
            passcodeErrorMessage: '',
            tooltipWidth: '150px',
            skillsLayout: {
                gutter: {
                    default: '15px',
                    [this.$vuetify.breakpoint.thresholds.xs]: '8px',
                },
                cols: {
                    default: 4,
                    [this.$vuetify.breakpoint.thresholds.lg]: 4,
                    [this.$vuetify.breakpoint.thresholds.md]: 3,
                    [this.$vuetify.breakpoint.thresholds.sm]: 2,
                    [this.$vuetify.breakpoint.thresholds.xs]: 1,
                },
                columnClass: 'skillBox',
            },
            skills: skillData,
            experiences: experienceData,
            education: educationData,
        };
    },
    methods: {
        setPasscodeDialog(visible: boolean): void {
            this.showPasscodeDialog = visible;
            this.passcodeValue = '';
            this.passcodeErrorMessage = '';
        },
        redirectToResume(withPasscode: boolean): void {
            new Promise((resolve, reject) => {
                let info: ContactInfo = {
                    address: 'Please contact via LinkedIn for additional contact info.',
                    phoneNumber: '',
                    email: '',
                };

                if (!withPasscode) {
                    resolve(info);
                    return;
                }

                fetch(`${window.location.protocol}//${window.location.hostname}/contact`, {
                    headers: {
                        'x-pass-key': this.passcodeValue as string,
                    },
                }).then(
                    response => {
                        if (response.status == 200) {
                            response.json().then(resolve);
                            this.setPasscodeDialog(false);
                        } else {
                            reject(new Error(`Passphrase is invalid.`));
                        }
                    },
                    () => reject(new Error('Something failed. Somehow let Matt know!'))
                );
            }).then(
                info => {
                    createPDF(skillData, experienceData, educationData, info as ContactInfo).then(pdfURL => {
                        const aTag = document.createElement('a');
                        document.body.appendChild(aTag);
                        aTag.href = pdfURL;
                        aTag.download = `matthew_swartzendruber_resume_${new Date()
                            .toISOString()
                            .slice(0, 10)
                            .replace(/-/g, '')}.pdf`;
                        aTag.click();
                        document.body.removeChild(aTag);
                    });
                },
                err => {
                    if (withPasscode) {
                        this.passcodeErrorMessage = err;
                    }
                }
            );
        },
    },
});
</script>
<style lang="sass" scoped>
@import '../../node_modules/vuetify/src/styles/styles.sass'
@import '../styles/sections.sass'
.box
    border-radius: 10px
    background-color: #303030
    color: white

.input-field
    padding: 15px

.error-message
    color: red
</style>
