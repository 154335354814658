<template lang="pug">
.box(@click="toggle")
    h3 {{ item.name }}
    v-expand-transition
        p(v-show="expanded") {{ item.description }}
</template>
<script lang="ts">
import Vue from 'vue';

export interface BoxItem {
    name: string;
    description: string;
}

export default Vue.extend({
    name: 'ExpandableBox',
    props: {
        item: Object as () => BoxItem,
    },
    data(): Record<string, unknown> {
        return {
            expanded: false,
        };
    },
    methods: {
        toggle() {
            this.expanded = !this.expanded;
        },
    },
});
</script>
<style lang="sass" scoped>
$description-padding: 15px

.box > h3
    font-weight: bold

.box > p
    text-align: left
    padding: 0 $description-padding 15px $description-padding
</style>
