<template lang="pug">
#banner
    v-app-bar(
        app
        dark
        dense
        flat
        fixed
        v-if="isMobile()"
    )
        v-app-bar-nav-icon(@click="toggleOverlay()")
    v-navigation-drawer(
        mini-variant
        v-bind:permanent="!isMobile()"
        v-bind:value="!hidden"
        @input="handleOverlayInputEvent"
        app
        dark
    )
        v-list
            v-list-item(v-for="route in routes" v-bind:key="route.meta.title" v-bind:to="route.path" link)
                v-list-item-icon
                    v-icon {{ route.meta.icon }}
                v-list-item-content
                    v-list-item-title {{ route.meta.title }}
</template>

<script lang="ts">
import VueRouter from 'vue-router';

export default {
    name: 'Banner',
    data(): Record<string, unknown> {
        const router: VueRouter = this.$router;
        return {
            hidden: true,
            routes: router.options.routes,
        };
    },
    mounted(): void {
        this.hidden = this.isMobile();
    },
    methods: {
        toggleOverlay(): void {
            this.hidden = !this.hidden;
        },
        isMobile(): boolean {
            return this.$vuetify.breakpoint.mobile;
        },
        handleOverlayInputEvent(isShown: boolean): void {
            this.hidden = !isShown;
        },
    },
};
</script>

<style lang="sass" scoped>
.nav-link
    font-weight: bold
    color: white
</style>
