<template lang="pug">
v-app
    Banner
    v-main#main(v-bind:style="{width: getWidth()}")
        transition(name="fade", mode="out-in")
            router-view
</template>

<script lang="ts">
import Banner from './components/Banner.vue';

export default {
    name: 'app',
    components: {
        Banner,
    },
    data(): Record<string, unknown> {
        return {
            bundler: 'Parcel',
        };
    },
    methods: {
        getWidth(): string {
            let width = '70%';
            switch (this.$vuetify.breakpoint.name) {
                case 'md':
                    width = '80%';
                    break;
                case 'sm':
                    width = '90%';
                    break;
                case 'xs':
                    width = '95%';
                    break;
            }

            return width;
        },
    },
};
</script>

<style lang="sass">
$image: url('./assets/background.png')
$image-repeat: repeat
$image-margin: 0

body
    background-image: $image
    background-repeat: $image-repeat
    margin: $image-margin

#app
    font-family: 'Avenir', Helvetica, Arial, sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-align: center
    background-image: $image
    background-repeat: $image-repeat
    margin: $image-margin

#main
    min-width: 360px
    margin: 0 auto 0 auto

.fade-enter-active, .fade-leave-active
    transition-duration: 0.3s
    transition-property: opacity
    transition-timing-function: ease

.fade-enter, .fade-leave-active
    opacity: 0
</style>
