<template lang="pug">
v-container
    v-container(v-for="experience in experiences")
        v-row.experienceHeader(no-gutters)
            v-col
                h5.text-left.font-weight-bold {{ experience.companyName }}
            v-col(md="auto")
                p.text-center {{ experience.location }}
            v-col
                p.text-right {{ experience.employmentDate }}
        v-row
            v-col
                p.text-left {{ experience.description }}
        v-row(v-for="title in experience.employmentTitles")
            v-container
                v-row
                    v-col
                        p.text-left.font-italic {{ title.title }}
                    v-col
                        p.text-right.font-italic {{ title.titleDate }}
                v-row
                    v-col
                        ul
                            li.text-left(v-for="point in title.summaryPoints") {{ point }}

</template>
<script lang="ts">
import Vue from 'vue';
import { EmploymentData } from '../data/experience';

export default Vue.extend({
    name: 'ProfessionalExperience',
    props: {
        experiences: Array as () => EmploymentData[],
    },
    data() {
        return {};
    },
});
</script>
<style lang="sass" scoped>
@import '../styles/sections.sass'
.v-application p, h5
    margin: 2px
    padding: 0
.experienceHeader
    border-bottom: 1px solid gray
</style>
