<template lang="pug">
#skills
    v-container
        masonry(
            v-bind:gutter="layoutOptions.gutter"
            v-bind:cols="layoutOptions.cols"
            v-bind:columnClass="layoutOptions.columnClass"
        )
            ExpandableBox(v-for="skill in skills" v-bind:item="skill")

</template>
<script lang="ts">
import Vue from 'vue';
import ExpandableBox, { BoxItem } from '../components/ExpandableBox.vue';

export interface LayoutOptions {
    gutter: { [key: string]: string | number };
    cols: { [key: string]: string | number };
    columnClass: string;
}

export default Vue.extend({
    name: 'Skills',
    components: {
        ExpandableBox,
    },
    props: {
        layoutOptions: Object as () => LayoutOptions,
        skills: Array as () => BoxItem[],
    },
});
</script>
<style lang="sass" scoped>
.box
    border-radius: 10px
    background-color: #303030
    color: white
</style>
